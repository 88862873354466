import React, { Suspense, lazy, useEffect } from "react";
import RouteConstants from "./RoutesConstant";
import { Switch, Route, useLocation } from "react-router-dom";
import GA4React from "ga-4-react";


const Dashboard = lazy(() => import("../Components/Admin/Dashboard"));
const IndustryWorkers = lazy(() => import("../Components/Admin/Dashboard/IndustryWorkers"));
const Home = lazy(() => import("../Components/Admin/Dashboard/Home"));
const LoginAdmin = lazy(() => import("../Components/Admin/LoginAdmin"));
const Employers = lazy(() => import("../Components/Admin/Dashboard/Employers"));
const PurchaseHistory = lazy(() => import("../Components/Admin/Dashboard/PurchaseHistory"));

const Login = lazy(() => import("../Components/Candidate/Login"));
const Signup = lazy(() => import("../Components/Candidate/Signup"));

const Admin = lazy(() => import("../Components/Admin"));

const Forgot = lazy(() => import("../Components/Candidate/Forgot/index"));
const Reset = lazy(() => import("../Components/Candidate/ResetCode/index"));
const Thankyou = lazy(() => import("../Components/Global/Thankyou"));
const Bulk = lazy(() => import("../Components/Global/Thankyou/Bulk"));
const User = lazy(() => import("../Components/Candidate/User"));
const UpdatePassword = lazy(() =>
  import("../Components/Candidate/UpdatePassword")
);

const Homepage = lazy(() => import("../Components/Global/Homepage"));
const HomeLogin = lazy(() => import("../Components/Global/Homepage/globalLogin"));
const LandingPage = lazy(() => import("../Components/Global/LandingPage"));
const Error = lazy(() => import("../Components/Global/Error"));

const Terms = lazy(() => import("../Components/Global/Terms"));
const TermsEmployer = lazy(() => import("../Components/Global/Terms/termsEmployer"));
const TermsCandidate = lazy(() => import("../Components/Global/Terms/termsCandidate"));
const CookiePolicy = lazy(() => import("../Components/Global/Terms/cookiePolicy"));
const PrivacyPolicy = lazy(() => import("../Components/Global/Terms/privacyPolicy"));

//Employeer Routes
const EmployeerLogin = lazy(() => import("../Components/Employer/Login"));
const EmployerSignup = lazy(() => import("../Components/Employer/Signup"));
const EmployerForgot = lazy(() => import("../Components/Employer/Forgot"));
const EmployerReset = lazy(() => import("../Components/Employer/Reset"));
const EmployerUpdatePassword = lazy(() => import("../Components/Employer/UpdatePassword"));
const EmployerVerify = lazy(() => import("../Components/Employer/Verify"));

const Main = lazy(() => import("../Components/Employer/Main/"));
const Pricing = lazy(() => import("../Components/Employer/Pricing/index"));

const Routes = () => {

  const location = useLocation();

  useEffect(() => {
    if(location.pathname.indexOf('admin') == -1)
    {
      const ga4 = GA4React.getGA4React();
      if (ga4) {
        ga4.pageview(location.pathname);
      }
      // <!-- Start of HubSpot Embed Code -->
      let script = document.createElement('script');
      script.src=`//js.hs-scripts.com/22753151.js`;
      script.id = "hs-script-loader";
      script.type="text/javascript";
      script.async = true;
      script.defer = true;
      
      document.body.appendChild(script);
      // <!-- End of HubSpot Embed Code -->
    }
  }, [location]);

  return (

    < >
      <Suspense
        fallback={
          <div className="lds-ripple loader">
            <div></div>
            <div></div>
          </div>
        }
      >
        <Switch>
          <Route exact path={"/"} render={(props) => <LandingPage {...props} />} />
          <Route
            exact
            path={RouteConstants.adminLogin}
            render={(props) => <LoginAdmin />}
          />
          <Route path={RouteConstants.admin} render={(props) => <Admin {...props} >
          </Admin>} />
          <Route path={RouteConstants.adminDashboard} render={(props) => <Admin {...props} >
          </Admin>} />

          <Route
              exact
              path={RouteConstants.terms}
              render={(props) => <Terms {...props} />}
          />

          <Route
              exact
              path={RouteConstants.termsEmployer}
              render={(props) => <TermsEmployer {...props} />}
          />

          <Route
              exact
              path={RouteConstants.termsCandidate}
              render={(props) => <TermsCandidate {...props} />}
          />


          <Route
              exact
              path={RouteConstants.cookiePolicy}
              render={(props) => <CookiePolicy {...props} />}
          />

          <Route
              exact
              path={RouteConstants.privacyPolicy}
              render={(props) => <PrivacyPolicy {...props} />}
          />

          <Route
            exact
            path={RouteConstants.login}
            render={(props) => <Login {...props} />}
          />
           <Route
            exact
            path={RouteConstants.authSignup}
            render={(props) => <Homepage {...props} />}
          />
          <Route
              exact
              path={RouteConstants.authlogin}
              render={(props) => <HomeLogin {...props} />}
          />
          <Route
            exact
            path={RouteConstants.forgetPassword}
            render={(props) => <Forgot {...props} />}
          />
          <Route
            exact
            path={RouteConstants.forgetPasswordEmployeer}
            render={(props) => <EmployerForgot {...props} />}
          />
          <Route
            exact
            path={RouteConstants.resetCode}
            render={(props) => <Reset {...props} />}
          />

          <Route
            exact
            path={RouteConstants.signup}
            render={(props) => <Signup {...props} />}
          />
          <Route
            exact
            path={RouteConstants.employerSignup}
            render={(props) => <EmployerSignup {...props} />}
          />
          <Route
            exact
            path={RouteConstants.employeerLogin}
            render={(props) => <EmployeerLogin {...props} />}
          />
          <Route
            exact
            path={`${RouteConstants.thankyou}/:from/:employer?`}
            render={(props) => <Thankyou {...props} />}
          />
           <Route
            exact
            path={`${RouteConstants.thankyouBulk}`}
            render={(props) => <Bulk {...props} />}
          />
          <Route
            exact
            path={`${RouteConstants.updatePassword}/:token/:bulk?`}
            render={(props) => <UpdatePassword {...props} />}
          />
             <Route
            exact
            path={`${RouteConstants.updatePasswordEmployer}/:token`}
            render={(props) => <EmployerUpdatePassword {...props} />}
          />
          <Route
            exact
            path={RouteConstants.user}
            render={(props) => <User {...props} />}
          />
        
          <Route
            exact
            path={RouteConstants.pricing}
            render={(props) => <Pricing {...props} />}
          />
          {/* <Route
            exact
            path={RouteConstants.editprofile}
            render={(props) => <EmployerProfile {...props} />}
          /> */}
          <Route
            exact
            path={RouteConstants.homePage}
            render={(props) => <LandingPage {...props} />}
          />

          {/*Employer Routes Start*/}
            <Route
              exact
              path={RouteConstants.forgetPassword}
              render={(props) => <Forgot {...props} />}
            />
            <Route
              exact
              path={RouteConstants.employeerLogin}
              render={(props) => <EmployeerLogin {...props} />}
            />
              <Route
            exact
            path={`${RouteConstants.employer}/main/:approved?`}
            render={(props) => <Main {...props} />}
          />
            <Route
              exact
              path={RouteConstants.forgetPasswordEmployeer}
              render={(props) => <EmployerForgot {...props} />}
            />
            <Route
              exact
              path={RouteConstants.resetEmployer}
              render={(props) => <EmployerReset {...props} />}
            />
            <Route
              exact
              path={RouteConstants.verify}
              render={(props) => <EmployerVerify {...props} />}
            />
          {/*Employer Routes Ends*/}



          <Route render={(props) => <Error {...props} />} />
        </Switch>
      </Suspense>
    </>
  );
};

export default Routes;
