const RouteConstants = {
  login: "/login",
  employeerLogin: "/employer/login",
  signup: "/signup",
  employerSignup: "/employer/signup",
  forgetPassword: "/forgot-password",
  forgetPasswordEmployeer: "/employer/forgot-password",
  resetCode: "/reset",
  errorpage: '/error',
  verify: "/verify-employer",
  resetEmployer: "/employer/reset",
  thankyou:"/thankyou",
  thankyouBulk:"/thankyou-bulk",
  updatePassword:"/update-password",
  updatePasswordEmployer:"/employer/resetPassword",
  user:'/user',
  homePage:"/",
  landingpage:"/landingpage",
  updatePassword: "/update-password",
  user: '/user',
  authSignup: "/global-signup",
  authlogin: "/global-login",
  employer: '/employer',
  pricing: '/pricing',
  admin: "/admin/",
  adminLogin: "/admin/login",
  adminDashboard: "/admin/dashboard",
  adminEmployers: "/admin/employers",
  adminIndustryWorker: "/admin/industry-workers",
  adminPurchaseHistory: "/admin/purchase-history",
  editprofile: '/editprofile',
  terms: '/terms',
  termsEmployer: '/terms-employer',
  termsCandidate: '/terms-candidate',
  cookiePolicy: '/cookie-policy',
  privacyPolicy: '/privacy-policy'
};
export default RouteConstants;
