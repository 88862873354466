import React, { useState } from "react";
import './App.css';
import Routes from './Routes/Routes'
import { BrowserRouter as Router } from "react-router-dom";
import 'antd/dist/antd.css';
import { EmployerBannerContext } from "./Components/Employer/Main/EmployerBannerContext"
import CookieConsent from "react-cookie-consent";


function App() {

  const [bannerObject, setBannerObject] = useState(
    {
      remainingNudges : 0,
      usedNudges : 0,
      name : '',
      companyName : '',
      picture : ''
    });
    
  return (
        <div className="App">
          <EmployerBannerContext.Provider value={[bannerObject, setBannerObject]}>
            <Router>
              <Routes/>
            </Router>
          </EmployerBannerContext.Provider>
          <CookieConsent
            location="bottom"
            buttonText="GOT IT"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            We use cookies to personalize your experience. By continuing to visit this website, you agree to our <a href="/cookie-policy" target="_blank">Cookie Policy</a>.
          </CookieConsent>

      </div>
  
  );
}

export default App;
